<script setup>
import { useAnalyticsStore } from "~/store/useAnalyticsStore";
import { storeToRefs } from "pinia";
import moment from "moment";

defineProps({
  linkId: {
    type: Boolean,
    required: true,
  },
});

const analyticsStore = useAnalyticsStore();
const { updateSelectedDateRange } = analyticsStore;
const { selectedDateRange } = storeToRefs(analyticsStore);

const formattedDateRange = computed(() => ({
  start: moment(selectedDateRange.value?.startDate?._d).format("DD MMM YYYY"),
  end: moment(selectedDateRange.value?.endDate?._d).format("DD MMM YYYY"),
}));

const activeTab = ref("clicks");
const tabOptions = ref([
  { label: "Clicks", value: "clicks" },
  { label: "Location", value: "locations" },
  { label: "Traffic Source", value: "traffic" },
]);
</script>

<template>
  <Sidebar
    header="Analytics"
    :visible="true"
    @update:visible="$emit('close')"
    :baseZIndex="10000"
    p-sidebar-lg
    position="bottom"
    class="rounded-t-2xl"
    style="height: 96%"
    id="lnk-analyics-bottombar"
  >
    <template #header>
      <h3 class="font-semibold text-xl">Analytics</h3>
      <SelectButton
        v-model="activeTab"
        :unselectable="false"
        :options="tabOptions"
        optionLabel="label"
        optionValue="value"
        dataKey="value"
        aria-labelledby="analytics-tab-btn"
        id="analytics-tab-btn"
        class="!shadow-none"
      />
    </template>

    <div v-auto-animate="{ duration: 300 }">
      <div class="flex justify-between mb-5">
        <CustomAnalyticsFilterChips />
        <CustomDateRangeSelector
          :selectedDateRange="formattedDateRange"
          @updateDateRange="updateSelectedDateRange"
        />
      </div>

      <!-- TODO: fix memory leak in this when you run npx nuxi analyze -->
      <AnalyticsClicksLayout2 v-if="activeTab === 'clicks'" :linkId="linkId" />
      <AnalyticsLocationsLayout v-else-if="activeTab === 'locations'" :linkId="linkId" />
      <AnalyticsTrafficSourcesLayout
        v-else-if="activeTab === 'traffic'"
        :linkId="linkId"
      />
    </div>
  </Sidebar>
</template>

<style lang="postcss">
#lnk-analyics-bottombar > .p-sidebar-header {
  @apply justify-evenly select-none;
}
#lnk-analyics-bottombar > .p-sidebar-header > .p-sidebar-header-content {
  @apply flex justify-between w-full items-center;
}
#lnk-analyics-bottombar > .p-sidebar-header {
  @apply justify-between;
}
#lnk-analyics-bottombar > .p-sidebar-content {
  @apply h-full;
}
/* tabs  button */
#analytics-tab-btn > .p-button {
  @apply !border-gray-300 !bg-white text-sm px-5 py-2;
}
#analytics-tab-btn > div.p-highlight {
  border-style: inherit !important;
  @apply font-bold !border-primary !bg-primary !text-white scale-100;
}
</style>
